.container {
    position: relative;
    max-width: 100%;
    margin-top: 50px;
  }
  
  .title {
    font-family: 'Playfair Display';
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: capitalize;
    color: #4F734F;
    margin-top: 100px;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    text-align: center;
    justify-content: center;
  }
  
  .imgpos {
    width: 100%;
    height: auto;
  }
  
  .image {
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    padding: 10px;
    text-align: center;
  }
  
  .text {
    color: white;
    font-size: 44px;
    font-weight: 400;
    text-align: center;
    font-family: 'Playfair Display';
    line-height: 100px;
    letter-spacing: 3px;
  }

  @media (max-width: 768px) {
    .image {
      width: 100%;
      height: auto;
    }
    .imgpos {
      width: 100%;
      height: auto;
    }
    .text {
      font-size: 18px;
      line-height: 45px;
      letter-spacing: 0;
    }
    .title {
      font-size: 38px;
      line-height: 60px;
      margin-top: 50px;
    
    }
  }
  