.container{
width: 100%;
height: 100%;
display: flex;
flex-direction: column;
gap: 50px;
}

.div1{
width: 100%;
height: 200px;
padding-left: 30px;
padding-right: 30px;
display: flex;
justify-content: center;
align-items: center;
}


.div1title{
    width: auto;
    height: auto;
    color: #4F734F;
text-align: center;
font-size: 90px;
font-family: Playfair Display;
font-style: normal;
font-weight: 700;
line-height: 137px;
text-transform: capitalize; 
}

.div2{
width: 100%;
height: 500px;
padding-left: 30px;
padding-right: 30px;
}

.div2image{
    width: 100%;
    height: 100%;
}

.div3{
width: 100%;
height: 400px;
display: flex;
flex-direction: row;
padding-left: 30px;
padding-right: 30px;
}

.div3left{
width: 50%;
height: 100%;
padding: 10px;
display: flex;
align-items: center;
justify-content: center;
text-align: center;
}

.div3right{
    width: 50%;
    height: 100%;
}

.div3lefttitle{
    width: auto;
    height: auto;
    text-align: center;
    color: #4F734F;
    font-size: 55px;
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.165px; 
}

.div3rightparagraph{
width: 100%;
height: 100%;

color: #A3A3A3;
font-size: 20px;
font-family: Montserrat;
font-style: normal;
font-weight: 500;
line-height: 26.667px;
letter-spacing: 0.4px;
text-transform: capitalize; 
}

.div4{
width: 100%;
height: 700px;
display: flex;
flex-direction: column;
gap: 30px;
padding-left: 17%;
padding-right: 17%;
}

.div41{
width: 100%;
height: 20%;
display: flex;
align-items: center;
justify-content: center;

padding-left: 50px;
padding-right: 50px;
}

.div42{
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.div43{
    width: 100%;
    height: 60%;

}

.div41title{
    width: auto;
    height: auto;
    color: #4F734F;
    text-align: center;
font-size: 55px;
font-family: Playfair Display;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.165px; 
}

.div42paragraph{
    width: 90%;
    height: 100px;
    color: #A3A3A3;
text-align: center;
font-size: 20px;
font-family: Montserrat;
font-style: normal;
font-weight: 500;
line-height: 26.667px;
letter-spacing: 0.4px; 
}

.div43image{
    width: 100%;
    height: 100%;
}



.div6{
width: 100%;
height: 800px;
display: flex;
text-align: center;
flex-direction: column;
justify-content: center;
align-items: center;
padding-top: 50px;
padding-left: 30px;
padding-right: 30px;


background-image: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  ),
  url("../../assets/images/Adamstown.png");
/* background-image: url('../../assets/images/Adamstown.png'); */
background-color: rgba(255, 255, 255, 0.5); /* Adjust the alpha (last value) to control the opacity of the white overlay */

background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}


.div6div1{
width: 100%;
height: 100px;
display: flex;
align-items: center;
justify-content: center;

}

.div6div1title{
    width: auto;
    height: auto;
    color: #4F734F;
    text-align: center;
    font-size: 55px;
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.165px; 
}

.div6div2{
width: 100%;
height: 400px;
}

.div6div2paragraph{
    width: 100%;
    height: auto;
    color: #171717;
text-align: center;
font-size: 20px;
font-family: Montserrat;
font-style: normal;
font-weight: 500;
line-height: 26.667px;
letter-spacing: 0.4px;
text-transform: capitalize; 
}



/*responsive mode*/

@media screen and (min-width:100px) and (max-width:600px){

    .div1{
        height: 50px;
        }

    .div1title{
    font-size: 50px;
    line-height: 50px;
    text-transform: capitalize; 
    }

    .div2{
        height: 300px;
        }

    .div3{
            width: 100%;
            height: 1200px;
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            padding-right: 30px;
        }


        .div3left{
            width: 100%;
            height: 30%;
            display: flex;
            align-items: center;
            text-align: center;
            }
            
            .div3right{
                width: 100%;
                height: 70%;
            }


        .div3lefttitle{
            width: 100%;
            font-size: 45px;
        }

        .div3lefttitle{
            font-size: 35px;
        }

        .div3rightparagraph{
            font-size: 20px;
            line-height: 24.667px;
            letter-spacing: 0.3px;
            }

        .div41title{
        font-size: 35px;
        }

        .div42paragraph{
            width: 90%;
            height: 200px;
        font-size: 20px;
        line-height: 24.667px;
        letter-spacing: 0.3px;
        }

        .div4{
            height: 600px;
            padding-left: 10%;
            padding-right: 10%;
            }


            .div6div1title{
                font-size: 35px;
            }

            .div6div2paragraph{
            font-size: 18px;
            line-height: 22.667px;
            letter-spacing: 0.2px;
            height: fit-content;
            }

            .div6{
                width: 100%;
                height: 900px;
                padding-top: 30px;
                }

                .div6div1{
                    width: 100%;
                    height:100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    }

                    .div6div2{
                        width: 100%;
                        height: 400px;
                        display: flex;
                        flex-grow: 1;
                        flex-direction: column;
                        }
}

@media screen and (min-width:600px) and (max-width:900px){
    .div1{
        height: 50px;
        }

    .div1title{
    font-size: 50px;
    line-height: 50px;
    text-transform: capitalize; 
    }

    .div2{
        height: 300px;
        }

    .div3{
            width: 100%;
            height: 600px;
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            padding-right: 30px;
        }

        .div3left{
            width: 100%;
            height: 30%;
            display: flex;
            align-items: center;
            text-align: center;
            }
            
            .div3right{
                width: 100%;
                height: 70%;
            }

        .div3lefttitle{
            width: 100%;
            font-size: 45px;
        }

        .div3lefttitle{
            font-size: 35px;
        }

        .div3rightparagraph{
            font-size: 20px;
            line-height: 24.667px;
            letter-spacing: 0.3px;
            }

        .div41title{
        font-size: 35px;
        }

        .div42paragraph{
            width: 90%;
            height: 200px;
        font-size: 20px;
        line-height: 24.667px;
        letter-spacing: 0.3px;
        }

        .div4{
            height: 600px;
            padding-left: 10%;
            padding-right: 10%;
            }


            .div6div1title{
                font-size: 35px;
            }

            .div6div2paragraph{
            font-size: 18px;
            line-height: 22.667px;
            letter-spacing: 0.2px;
            height: fit-content;
            }

            .div6{
                width: 100%;
                height: 800px;
                padding-top: 30px;
                }

                .div6div1{
                    width: 100%;
                    height:100px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    }

                    .div6div2{
                        width: 100%;
                        height: 400px;
                        display: flex;
                        flex-grow: 1;
                        flex-direction: column;
                        }


}

@media screen and (min-width:900px) and (max-width:1200px){
    .div1{
        height: 50px;
        }

    .div1title{
    font-size: 50px;
    line-height: 50px;
    text-transform: capitalize; 
    }
}

@media screen and (min-width:2000px){

        .div1title{
            font-size: 120px;
        }

        .div2{
            height: 1200px;
            padding-left: 100px;
            padding-right: 100px;
            }

            .div3{
                height: 700px;
                }

                .div3lefttitle{
                    font-size: 80px;
                    letter-spacing: 0.3px; 
                }
                

            .div3rightparagraph{

                font-size: 50px;    
                line-height: 50.667px;
                letter-spacing: 0.4px;
                }    

                .div4{
                    height: 1500px;
                    }

                    .div41title{
                    font-size: 80px;
                    letter-spacing: 0.3px; 
                    }

                    .div42paragraph{
                    font-size: 50px;
                    line-height: 50.667px;
                    letter-spacing: 0.4px; 
                    }

                    .div6{
                        height: 1000px;
                        }

                        .div6div1title{
                            font-size: 80px;
                            letter-spacing: 0.4px; 
                        }

                        .div6div2{
                            width: 100%;
                            height: 700px;
                            }
                            
                            .div6div2paragraph{
                            font-size: 50px;
                            line-height: 50.667px;
                            letter-spacing: 0.4px;
                            text-transform: capitalize; 
                            }
}


