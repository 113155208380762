.titre{
   
font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 60px;
text-align: center;
letter-spacing: 0.02em;
text-transform: capitalize;
align-items: center;
color: #4F734F;
flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;
}
.soustitre{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 32px;
/* identical to box height, or 178% */
text-align: center;
font-feature-settings: 'liga' off;

color: rgba(47, 35, 79, 0.8);
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}
.left{
    width: 80%;
    height: 100%;
    margin-left: 112px;
  
}
.image{
    width: 100%;
    height: 100%;
}
.right{
    width: 80%;
    height: 100%;
}
.box{




height: 100%;

background: #F6F3FF;
border-radius: 4px;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 1;
}
.title{
    font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
font-size: 28px;
line-height: 42px;
/* identical to box height, or 150% */
padding-left: 42px;
padding-top: 42px;
letter-spacing: 0.02em;
text-transform: capitalize;

color: #4F734F;
}
.paragraphe{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 26px;
/* or 186% */
width: 90%;
font-feature-settings: 'liga' off;

color: rgba(47, 35, 79, 0.8);


/* Inside auto layout */
padding-left: 42px;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;

}
.content{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
/* or 119% */
padding-left: 42px;
letter-spacing: 0.04em;
text-transform: capitalize;

color: #2F234F;


/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}
.content1{
    font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
font-size: 27px;
line-height: 63px;
/* identical to box height, or 233% */

letter-spacing: 0.04em;
text-transform: capitalize;

color: #2F234F;

padding-left: 42px;
/* Inside auto layout */

flex: none;
order: 6;
align-self: stretch;
flex-grow: 0;
}
@media screen and (max-width:600px) {
    .left{
        margin-left: 50px;
    }
    .right{
        margin-left: 50px;
        margin-top: 30px;
    }
    
}
@media screen and (max-width:1300px) {
    .left{
        margin-left: 50px;
    }
    .right{
        margin-left: 50px;
     
    
}}