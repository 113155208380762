.left{
    width:100%;
    height:100%;
  
}
.title{
    font-family: Playfair Display;
font-size: 55px;
font-weight: 700;
line-height: 73px;
letter-spacing: 0.003em;
display:flex;
text-align:center;
justify-content:center;
color: #4F734F;
 margin-top:100px;
 

}
.position{
    padding-left:20px;
    
}
.text{
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    padding-top:50px;
    font-family: Montserrat;
font-size: 20px;
font-weight: 400;
line-height: 32px;
letter-spacing: 0.01em;
width:80%;

padding-left:40px;
color:grey;
}
.text1{
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.01em;
    width:80%;
  
    padding-left:40px;
    color:grey; 
}
.right{
    width:100%;
    height:100%;  
}

.group1{
    position: absolute;
    bottom: 0;
    left: 0;
}


.red{
    width:100%;
    height:100%; 
    display:block;
    object-position:left bottom


    
}


.unsplash{
    width:100%;
    height:100%; 

}
.container{
    position:relative;
    
}

.blue{
    display:flex;
 align-items:center; 
transform:scale(0.88,0.9);


}
