.titre{
width: 100%;
font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
font-size: 90px;
line-height: 137px;
text-align: center;
text-transform: capitalize;
color: #4F734F;

}
.image{
    width: 100%;
    height:100%;
    width: 1235px;
height: 545px;
margin-left: 87px;
  
}
.title{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 27px;
/* or 133% */

letter-spacing: 0.02em;
text-transform: capitalize;

color: #000000;
padding-left: 87px;
padding-top: 30px;

}
.paragraphe{
    color: #A3A3A3;
    font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 27px;
/* or 133% */
padding-left: 87px;
letter-spacing: 0.02em;
text-transform: capitalize;
width: 90%;
}

@media screen and (max-width:600px) {
    .titre{
        font-weight: 700;
font-size: 30px;
line-height: 97px;
    }
    .image{
        width: 100%;
        height:100%;
        margin-left: 0px;
      
    }
    .paragraphe{
        padding-left: 20px;
    }
    .title{
        padding-left: 20px;  
    }
    
}
@media screen and (max-width:1300px) {
    .image{
        width: 100%;
        height:100%;
        margin-left: 0px;
      
    }  
}