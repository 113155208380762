.HeaderLarge{
    /* background-image: 
      url("../../assets/images/HomeHeader.png"); */
    /* background-position: center; */
    /* background-repeat: no-repeat;
    background-size: cover ;
    height: 100vh; */
    position: absolute;
    width: 100%;
    z-index: 2;
}

.HeaderMobile{
    /* background-image: 
      url("../../assets/images/HomeHeader.png");
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    margin-bottom: 50%; */
    position: absolute;
    width: 100%;
    z-index: 2;
}


.AppBarLarge{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline: 3%;
    gap: 12%;
    padding-top: 0.6%;
    padding-bottom: 0.6%;
    backdrop-filter: blur(5px);
    box-shadow: 0px 4px 62px 0px rgba(0, 0, 0, 0.09);
}

.AppBarMobile{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-inline: 3%;
    padding-top: 1%;
    padding-bottom: 1%;
    box-shadow: 0px 4px 62px 0px rgba(0, 0, 0, 0.09);
}

p{
    margin: 0px;
    color: #737373;
    font-weight: 600;
    font-family: Montserrat, sans-serif;
}


@media(max-width: 300px){
    .MenuIcon{
        width: 12px;
    }
    p{
        font-size: 12px;
    }
}

@media (min-width:600px) and (max-width: 800px) {
    .AppBarLarge{
        padding-inline-start: 2%;
        gap: 6%;
    } 
}


@media (min-width:1200px) and (max-width: 1535px) {
    .AppBarLarge{
        padding-inline: 5%;
    }
}

@media (min-width:900px) and (max-width: 1000px) {
    .AppBarLarge{
        padding-inline-start: 2%;
        gap: 7%;
    } 
}

@media (min-width:1000px) and (max-width: 1200px) {
    .AppBarLarge{
        padding-inline-start: 4.5%;
    }
}