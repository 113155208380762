.Logo {
    width:200px;
    height: 50px;
}

@media (max-width:  300px ) {
  .Logo {
    width: 50px;
    height: 13px;
  }
}

@media (min-width:  300px ) and (max-width:  600px ) {
  .Logo {
    width: 100px;
    height: 28px;
  }
}

@media (min-width:  600px ) and (max-width:  900px ) {
  .Logo {
    width: 120px;
    height: 32px;
  }
}

@media (min-width:  900px ) and (max-width:  1200px ) {
  .Logo {
    width: 160px;
    height: 40px;
  }
}

@media (min-width:  1200px ) and (max-width:  1535px ) {
  .Logo {
    width:240px;
    height: 60px;
}
}

@media (min-width:  1535px ) {
  .Logo {
    width:250px;
    height: 62px;
}
}
