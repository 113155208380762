.vpc {
  position: relative; 
  height: 100vh;
  margin-bottom: 8%;
  z-index: 3;
}

.vpc video{
  height: 100%;
  width: 100%;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0; /* Add this to remove the default margin */
  padding: 0;
  object-fit: cover;
}

.vpc:before {
  content:"";
  position: absolute;
  top:0;
  right:0;
  left:0;
  bottom:0;
  z-index:1;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6));
}

@media (max-width: 600px){
  .vpc{
    height: 300px;
  }
}
@media (min-width: 600px) and (max-width: 1050px){
  .vpc{
    height: 55vh;
  }
}