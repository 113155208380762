.home{
    display: flex;
    align-items: start;
    /* min-height:100vh; */
}
.home .content{
    max-width:55rem;
    padding-left:50px;
    padding-top: 10%;

}
.title{
font-family: Playfair Display;
font-size: 72px;
font-weight: 700;
line-height: 90px;
letter-spacing: 0em;
text-align: left;
color:white;
padding:1rem 0;

}
.soustitre{
    font-family: Montserrat;
font-size: 20px;
font-weight: 500;
line-height: 32px;
letter-spacing: 0px;
text-align: left;
color:white;
padding:1rem 0;


}
.bouton{
    width: Hug (128px);
height: Hug (48px);
background: #4F734F;
text-decoration: none;
color:white;
padding: 12px 20px 12px 20px;
border-radius: 8px;
gap: 8px;

border:none;


}
.bouton:hover{
    background:white;
    color:#4F734F;
}
@media(max-width:991px){
    .content{
        font-size:55%;
    }

}
@media(max-width:600px){
    .home{
        min-height:50vh;
    }

    .home .content{
        padding-left: 10px;
    }
    .title{
        font-family: Playfair Display;
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color:white;
    padding:1rem 0;
    padding-left:5px
    }
    .soustitre{
        font-family: Montserrat;
    font-size: 15px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color:white;
    padding:1rem 0;
    padding-left:5px;
    padding-right:2px
    
    } 
}
