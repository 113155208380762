.position{
    position:relative;
     
 
 }
 
 
 .red{
     width:100%;
     height:100%; 
     display:flex;
   justify-content:flex-start
     
 }
 .red1{
     width:100%;
     height:100%; 
   display:flex;
   justify-content:flex-end;
 
 
  
   
 }
 
 .unsplash{
     width:100%;
     height:90%;  
 }
 .group1{
  display:flex;
  align-items:flex-end; 
 
 }
 .blue{
     display:flex;
  align-items:center; 
 transform:scale(0.94,1.22);
 z-index:1;
 
 }
 .right{
     padding-top:20%
 }
 .title{
     font-family: Playfair Display;
     display:flex;
     text-align:center;
     justify-content:center;
 font-size: 55px;
 font-weight: 700;
 line-height: 73px;
 letter-spacing: 0.003em;
 color:
 #4F734F;
 margin-top:100px;
 
 }
 .text{
     font-family: Montserrat;
 font-size: 20px;
 font-weight: 400;
 line-height: 32px;
 letter-spacing: 0.01em;
 padding:10px;

 color:#464646;
 width:90%;
 display:flex;
 justify-content:center;
 align-items:center;
 color:grey
 
 
 }
 .right{
     width:100%;
     height:100%;
     display:flex;
     flex-direction:column;
     justify-content:center;
     align-items:center;
 }
 
 .text1{
     font-family: Montserrat;
 font-size: 20px;
 font-weight: 400;
 line-height: 32px;
 letter-spacing: 0.01em;
 padding:10px;
 
 color:grey;
 width:90%;
 }
 @media screen and (max-width:1200px){
     .text{
         padding-top:0px
     }
 }
  