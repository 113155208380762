.title {
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  align-items: center;
  color: #4f734f;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
}

.subtitle {
  text-align: center;
  color: rgba(171, 171, 171, 1);
  font-size: 30px;
  font-weight: 400px;
  line-height: 37px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 4%;
}

.cadre {
  display: flex;

  width: 1300px;
  height: 750px;
  border-radius: 30px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1))
    linear-gradient(0deg, #ffffff, #ffffff);
  box-shadow: 4px 4px 30px 0px #0000001a;
  border: 1px solid #0000001a;
}

.total {
  display: flex;
  justify-content: center;
}
.check {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.poscheck {
  display: flex;
  flex-direction: row;
}
.left {
  flex: 1; /* Align items to the left */
  padding: auto;
}

.contact {
  width: 533px;
  height: 702px;
  background-color: #4f734f;
  border-radius: 30px;
  display: flex;
  justify-content: flex-start;
}

.message {
  width: 100%;
}

.info {
  padding-left: 30px;
}

.soustitre {
  color: white;
  font-weight: 300;
}

.titre {
  margin-top: 50px;
  color: white;
  font-weight: 900;
}

.location {
  display: flex;
  flex-direction: column;
  margin-top: 100px;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 30px;
  color: white;
}

.row1 {
  color: white;
}

.hours {
  margin-top: 50px;
  color: white;
}

.hours1 {
  color: white;
}

.position {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.somme {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 50px;
}
.right {
  flex: 1;
  margin-left: 20px;
}
.posimage {
  display: flex;
  justify-content: center;
}

.bouton {
  width: 150px;
  height: 50px;
  color: white;
  background-color: black;
  font-weight: 900;
}

.tric {
  display: flex;
  flex-direction: column;
  gap: 0px;
}
.posbouton {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .title {
    font-size: 15px;
    line-height: 20px;
  }

  .subtitle {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 2%;
  }
  .cadre {
    width: 100%;
    height: auto;
  }
  .total {
    width: 100%;
  }
  .left {
    width: 100%;
    padding-left: 0px;
  }
  .right {
    width: 100%;
    margin-left: 0px;
  }
  .contact {
    width: 100%;
  }
}
/*ahmads' work*/

.contactUs {
  font-family: Playfair Display;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(79, 115, 79, 1);
}
.card {
  width: 85%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 0px 0px 65.19188690185547px 32.595943450927734px
    rgba(0, 0, 0, 0.03);
  border-radius: 15px;
}
/*contact info card*/
.contactInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: rgba(79, 115, 79, 1);
  color: white;
  width: 40%;
  border-radius: 15px;
  padding: 25px;
  gap: 70px;
}

.contactInfo .div1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
}

.contactInfo .div2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
}

.title {
  font-size: 20px;
  font-family: Poppins;
  font-weight: 600;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: inherit;
}

.contactMethod {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
}

.contactInfoDetails {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

@media (max-width: 600px) {
  .card {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .contactInfo {
    width: 100%;
  }
}
/*form*/
.formContainer {
  width: 55%;
  margin: 0 auto;
  padding: 20px;
}

.formRow {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.formField {
  display: flex;
  flex-direction: column;
}

.formField error {
  color: red;
}

.staticLabel {
  color: #000;
  display: block;
  font-size: 14px;
}

input,
textarea {
  width: 100%;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
}

.checkboxGroup {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 20px;
  gap: 10px;
}

.optInOption {
  width: max-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  font-size: 14px;
}

.checkboxLabel {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  font-size: 12px;
  cursor: pointer;
}

select {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  border: none;
  border-bottom: 1px solid #ccc;
  background-color: transparent;
}

.submitRow {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}
.submitButton {
  background: rgba(1, 28, 42, 1);
  box-shadow: 0px 0px 15.21143913269043px 0px rgba(0, 0, 0, 0.12);
  color: white;
  padding: 10px;
  border-radius: 4px;
}

@media (max-width: 600px) {
  .formContainer {
    width: 100%;
  }
  .formRow {
    flex-direction: column;
  }
  .checkboxGroup {
    flex-direction: column;
  }
}

@media (min-width: 600px) and  (max-width: 1000px) {
  .checkboxGroup{
    flex-direction: column;
  }
  }

