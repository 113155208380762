main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FBFBFB;
    margin-bottom: 100px;
}

.header{
    position: absolute;
    top: 200px;
    left: 0;
}