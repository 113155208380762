.left{
    width: 40%;
    height: 100%;
  
}
.right{
    width: 60%; 
    height: 100%;  
}
.image{
    width: 100%;
    height: 100%;
}
.title{
    font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
font-size: 109.6px;
line-height: 137px;
text-transform: capitalize;
color: #4F734F;
text-align: center;

}
.left{
    width: 100%;

    height: 100%;
}
.right{
    width: 100%;
    height: 100%;
}

.nombre{
    font-family: 'Playfair Display';
font-style: normal;
font-weight: 800;
font-size: 62.4px;
line-height: 83px;
/* identical to box height, or 133% */

letter-spacing: 0.02em;

color: rgba(79, 115, 79, 0.53);

}
.soustitre{
    font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
font-size: 48px;
line-height: 60px;
/* identical to box height, or 125% */
margin-left: 116px;
letter-spacing: 0.02em;
text-transform: capitalize;

color: #4F734F;

}

.vector{
    width: 0px;
height: 105px;
margin-left:11px ;
top: 854px;

border: 2px dashed #4F734F;
    
}
.paragraphe{
    font-family: 'Montserrat';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 24px;
/* or 150% */

font-feature-settings: 'liga' off;

color: rgba(47, 35, 79, 0.5);

width: 90%;
/* Inside auto layout */

padding: 11px;
flex: none;
order: 1;
align-self: stretch;
flex-grow: 0;
}
.intertitre{
    padding: 11px;
    font-family: 'Playfair Display';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
/* identical to box height, or 133% */

letter-spacing: 0.02em;
font-feature-settings: 'liga' off;

color: #2F234F;


/* Inside auto layout */

flex: none;
order: 0;
flex-grow: 0;
}
.position{
    display: flex;
    flex-direction: column;
    margin-left:80px ;
  
    margin-top: 86px;
}
.position1{
    display: flex;
    flex-direction: column;
    margin-left:80px ;
 
}
.cadre{
    background: #FFFFFF;
/* Dark Shadow */
margin-top: 100px;
box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
border-radius: 8px;
width: 70%;
height: 100%;

/* Inside auto layout */

flex: none;
order: 0;
align-self: stretch;
flex-grow: 0;

}
.cadre1{
    background: #FFFFFF;
    /* Dark Shadow */
  
    box-shadow: 0px 10px 20px rgba(41, 41, 42, 0.07);
    border-radius: 8px;
    width: 70%;
    height: 100%;
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0; 
}
.total{
    display: flex;
    flex-direction: row;
    gap: 33px;
    padding-top: 30px;
   
}
.bouton{
    width: 130px;
height: 64px;
border: none;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 24px;
/* identical to box height, or 150% */


color: #FFFFFF;
margin-left: 122px;
background-color: #4F734F;
/* Inside auto layout */
cursor: pointer;
flex: none;
order: 0;
flex-grow: 0;
}
.container{
    display:flex
}


@media screen and (max-width:600px) {
    .title{
        font-size: 59.6px;
        line-height: 57px;  
    }
    .left{
        width: 100%;
        height: 100%;
        padding-top: 0px;
    }
    .right{
        width: 100%;
        height: 100%; 
    }
    .position{
        margin-left: 3px;
    }
    .position1{
        margin-left: 3px;
        
    }
    .cadre1{
        margin-top: 11px;
    }
    .vector{
        margin-left: 13px;
    }


}
@media screen and (max-width:900px) {
    .right{
        width: 100%;
        height: 100%; 
    }
    .left{
        width: 100%;
        height: 100%; 
        padding-top: 0px;  
}
.image{
    width: 100%;
        height: 100%;  
}
}
@media screen and (max-width:1200px) {
    .cadre{
        width: 80%;
    }
    .cadre1{
        margin-top: 11px;
    }
    .vector{
        margin-left: 6px;
    }
    .position{
        margin-left: 11px;
    }
    .position1{
        margin-left: 11px;
    }
    .right{
        width: 100%;
        height: 100%; 
    }
    .left{
        width: 100%;
        height: 100%; 
        padding-top: 0px;
    }
    .image{
        width: 100%;
            height: 100%;  
    }
  
}