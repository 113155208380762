.left{
    width: 120%;
    background-color: 
    #4F734F;
    display: flex;
   
 
    
    justify-items: center;
    align-items: center;
}
 


.photo{
    width: 100%;
    height: 100%;
}
.title{
    font-family: 'Playfair Display';
font-style: normal;
font-weight: 700;
font-size: 46.5424px;
line-height: 70px;
/* or 150% */

text-transform: capitalize;

color: #FFFFFF;

}
.paragraphe{
  
    font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 32px;
width: 80%;
/* or 178% */


color: #FFFFFF;
}
.image{
    width:50%;
    height:80%;
    margin-left: 40px;
    display:flex;
    align-items: center;
    justify-content: center;
}
.position{
    display: flex;
    flex-direction: row;
 
    
    justify-items: center;
    align-items: center;
}
.life{
    display: flex;
    justify-items: center;
    align-items: center;
   width:80%;
   height:100%;
}


.right{
    width: 100%;
}
@media screen and (max-width:600px) {
    .title{
        font-size: 26.5424px;
line-height: 40px;
margin-top: 0px;
    }
    .position1{
        margin-top: 8px;
    }
    .photo{
        width: 100%;
        height: 100%;
    }
    .left{
        width: 100%;
    }
    
    .image{
        width:100%;
        height:100%;}
        .life{
            display: flex;
            justify-items: center;
            align-items: center;
           width:100%;
           height:100%;
        }
  
}
@media screen and (max-width:1000px) {
    .title{
        font-size: 26.5424px;
line-height: 40px;
margin-top: 0px;
    }  
    .left{
        width: 100%;
    }
    .image{
        width:100%;
        height:100%;
    margin-left:0px;
}
        .life{
            display: flex;
            justify-items: center;
            align-items: center;
           width:100%;
           height:100%;
        }
}
@media screen and (max-width:1200px) {
   
   
    .photo{
        width: 100%;
        height: 100%;
    } 
    .left{
        width: 100%;
    }
    .image{
        width:100%;
        height:100%;
        margin-left:0px;}
        .life{
            display: flex;
            justify-items: center;
            align-items: center;
           width:100%;
           height:100%;
        }
}